import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DropTargetMonitor, XYCoord, useDrop } from "react-dnd";
import { carsSings, streetSigns, trafficSigns, especialCarsSings, peopleSings,objectSings } from "../data";
import BoardFooter from './BoardFooter';
import { Element as CustomElement } from "./Element/types";
import { v4 as uuid } from "uuid";
import Moveable from "react-moveable";
import '../index.css'
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from "../constants";

interface boardProps {
  onDrop: (item: CustomElement) => void;
  items: Array<CustomElement>;
  isShowGrid?: boolean;
  image: string | null;
  setItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
  setBoardItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
}

export const Board = React.forwardRef<any, boardProps>((props, ref) => {
  const { onDrop, items, image, setItems, setBoardItems } = props;
  const [selectedElement, setSelectedElement] = useState<CustomElement | null>(null);
  const [frame, setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    width: 100,
    height: 100,
  });
  const [color, setColor] = useState<string>('#fff');
  const svgCanvasRef = useRef<SVGSVGElement | null | any>(ref);
  const selectedElementRef = useRef<SVGImageElement | null>(null);

  const acceptedTypes = trafficSigns
    .concat(streetSigns)
    .concat(carsSings)
    .concat(especialCarsSings)
    .concat(peopleSings)
    .concat(objectSings)
    .map((sign) => sign.source);

  const [, drop] = useDrop(
    () => ({
      accept: acceptedTypes,
      drop(item: CustomElement, monitor) {
        const delta = monitor.getClientOffset() as XYCoord;
        const left = Math.round(delta.x / 100) * 100 - 400;
        const top = Math.round(delta.y / 100) * 100 - 200;
        onDrop({
          left: left,
          top: top,
          source: monitor.getItemType() as string,
          height: item.height,
          width: item.width,
          id: item.id,
          icon: item.icon,
          name: item.name,
        });
        return undefined;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType() as string,
      }),
    }),
    [onDrop]
  );

  drop(svgCanvasRef);

  const handleColorChange = async (color: any) => {
    if (selectedElementRef.current) {
      const href = selectedElementRef.current.getAttribute('href');
      if (href) {
        const response = await fetch(href);
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("image/svg+xml")) {
          let svgText = await response.text();
          //svgText = svgText.replace(/fill="[^"]*"/g, `fill="${color.hex}"`);
          svgText = svgText.replace(/\.st0{fill:[^;]*;/g, `.st0{fill:${color.hex};`);
          //svgText = svgText.replace(/\.st1{fill:[^;]*;/g, `.st1{fill:${color.hex};`);
          //svgText = svgText.replace(/\.st2{fill:[^;]*;/g, `.st2{fill:${color.hex};`);
          //svgText = svgText.replace(/\.zona:hover{fill:[^;]*;/g, `.zona:hover{fill:${color.hex};`);
          //svgText = svgText.replace(/\.marcado{fill:[^;]*;/g, `.marcado{fill:${color.hex};`);
          //svgText = svgText.replace(/\.marcado:hover{fill:[^;]*;/g, `.marcado:hover{fill:${color.hex};`);
          const svgBlob = new Blob([svgText], { type: 'image/svg+xml' });
          const newHref = URL.createObjectURL(svgBlob);
          selectedElementRef.current.setAttribute('href', newHref);

          const updatedItems = items.map(item => {
            return item.id === selectedElement?.id ? { ...item, source: newHref } : item;
          });
          setItems(updatedItems);
          setBoardItems(updatedItems);
        }
      }
    }
  };

  const handleDelete = () => {
    if (selectedElement) {
      const updatedItems = items.filter(item => item.id !== selectedElement.id);
      setSelectedElement(null);
      setItems(updatedItems);
      setBoardItems(updatedItems);
    }
  };

  return (
    <>
      <svg
        ref={svgCanvasRef}
        width={MAX_COLUMN * WIDTH_AND_HEIGHT}
        height={MAX_ROW * WIDTH_AND_HEIGHT}
        style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}
      >
        {items.map((item, key) => {
          const { left, top, source, height, width } = item;
          const isSvgSource = source.endsWith('.svg');
          return (
            <image
              ref={selectedElementRef}
              href={source}
              x={left}
              y={top}
              height={`${height ?? 100}`}
              width={`${width ?? 100}`}
              style={{ cursor: "move", transformOrigin: `${left + width / 2}px ${top + height / 2}px` }}
              onMouseDownCapture={(event) => {
                setSelectedElement(item);
                setFrame({
                  translate: [left, top],
                  rotate: 0,
                  width: width,
                  height: height,
                });
                selectedElementRef.current = event.currentTarget;
              }}
            />
          );
        })}
      </svg>
      {selectedElement && (
        <>
          <Moveable
            target={selectedElementRef.current}
            resizable
            rotatable
            draggable
            onResize={({ target, width, height }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
              setFrame({
                ...frame,
                width,
                height,
              });
              target.style.transformOrigin = `${frame.translate[0] + width / 2}px ${frame.translate[1] + height / 2}px`;
            }}
            onRotate={e => {
              e.target.style.transform = e.drag.transform;
            }}
            onDrag={e => {
              e.target.style.transform = e.transform;
            }}
          />
          <BoardFooter onDeselect={() => setSelectedElement(null)} color={color} handleColorChange={handleColorChange} isSvgSource={selectedElement?.source.endsWith('.svg')} onDelete={handleDelete} />
        </>
      )}
    </>
  );
});

interface StateFulProps {
  image: string | null;
  setBoardItems: React.Dispatch<React.SetStateAction<CustomElement[]>>;
}

export const StatefulTargetBoard = forwardRef<any, StateFulProps>(
  (props, ref) => {
    const [items, setItems] = useState<CustomElement[]>([]);
    const { image, setBoardItems } = props;
    const [selectedImage, setSelectedImage] = useState<string | null>(image);

    const handleDrop = useCallback((item: CustomElement | CustomElement[]) => {
      if (Array.isArray(item)) {
        setItems(item);
        setBoardItems(item);
      } else {
        const newItems = [
          ...items,
          {
            id: uuid(),
            top: item.top,
            left: item.left,
            width: item.width,
            height: item.height,
            source: item.source,
            icon: item.icon,
            name: item.name,
          },
        ];
        setItems(newItems);
        setBoardItems(newItems);
      }
    }, [items, setBoardItems]);

    useEffect(() => {
      if (image) {
        setSelectedImage(image);
      } else {
        const capturedImage = localStorage.getItem('capturedMapImage');
        if (capturedImage) {
          setSelectedImage(capturedImage);
          localStorage.removeItem('capturedMapImage');
        }
      }
    }, [image]);

    return <Board ref={ref} onDrop={handleDrop} items={items} image={selectedImage || image} setItems={setItems} setBoardItems={setBoardItems} />;
  }
);