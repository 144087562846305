import { useEffect, useState } from "react"
import { Coordinates } from "./MapPage"

type MarkerProps = {
  coordinates: Coordinates
  map?: google.maps.Map
}

export const Marker = ({ map, coordinates }: MarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>()

  const { lat, lng } = new google.maps.LatLng(coordinates)

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }
  }, [])

  useEffect(() => {
    if (marker) {
      if (!lat && !lng) {
        return marker.setMap(null)
      }

      marker.setOptions({
        map,
        position: { lat: lat(), lng: lng() },
      })
    }
  }, [lat, lng, map, marker])

  return null
}
