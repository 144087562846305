import { DndProvider } from "react-dnd";
import "./App.css";
// import { StatefulTargetBoard as Board } from "./components/Board";
import { MainLayout } from "./components/MainLayout";
// import { SidebarOptions } from "./components/SidebarOptions";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MapPage from './components/MapPage';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          <Route path="/map" element={<MapPage />} />
          <Route path="/" element={<MainLayout />} />
        </Routes>
      </Router>
    </DndProvider>
  );
}

export default App;
