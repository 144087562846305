import { useEffect, useRef } from "react"
import { SetCoordinates } from "./MapPage"

type SearchComponentProps = {
  setCoordinates: SetCoordinates
}

export function SearchComponent({ setCoordinates }: SearchComponentProps) {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        ref.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "es" },
        },
      )
      autocomplete.setFields(["geometry"])
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()

        const lat = place.geometry?.location?.lat()
        const lng = place.geometry?.location?.lng()

        if (lat && lng) {
          return setCoordinates({
            lat,
            lng,
          })
        }
      })
    }
  }, [])

  return <input ref={ref} placeholder="Introduzca la dirección" className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
}
