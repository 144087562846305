import { useState, useRef } from "react";
import { Map } from "./Map";
import { Search } from "./Search";
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { Header } from './Header';
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from '../constants';

export type Coordinates = google.maps.LatLngLiteral | google.maps.LatLng;
export type SetCoordinates = (coordinates: Coordinates) => void;

const defaultCoordinates: Coordinates = {
  lat: 37.3891,
  lng: -5.9845,
};

export const MapPage = () => {
  const [coordinates, setCoordinates] = useState<Coordinates>(defaultCoordinates);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const componentRef = useRef(null);
  const navigate = useNavigate();

  const captureMapScreenshot = () => {
    const mapElement = document.querySelector('.map-container') as HTMLElement;
    if (mapElement) {
      setTimeout(() => {
        html2canvas(mapElement, {
          useCORS: true,
          logging: true,
          backgroundColor: null,
          width: MAX_COLUMN * WIDTH_AND_HEIGHT,
          height: MAX_ROW * WIDTH_AND_HEIGHT,
        }).then(canvas => {
          const image = canvas.toDataURL('image/png');
          localStorage.setItem('capturedMapImage', image);
          navigate('/');
        });
      }, 1000);
    }
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "90vh",
        }}
      >
        <div
          style={{
            flex: 30,
            maxWidth: 300,
            padding: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>
            <Search setCoordinates={setCoordinates} />
            <button
              className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-blue-500 text-white mb-2 mt-6"
              onClick={captureMapScreenshot}
            >
              Cargar ubicación
            </button>
            <button
              className="w-full relative overflow-hidden rounded-md px-3.5 py-2.5 bg-gray-500 text-white mb-2"
              onClick={() => navigate('/')}
            >
              Volver
            </button>
          </div>
        </div>
        <div
          ref={componentRef}
          style={{
            flex: 70,
            padding: 10,
            height: "100%",
            overflow: "scroll",
          }}
        >
          <Map coordinates={coordinates} />
        </div>
      </div>
    </div>
  );
};

export default MapPage;