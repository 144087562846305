import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ReportData {
  coatOfArms: string;
  townHallName: string;
  registrationNumber: string;
  inspector: string;
  accidentAddress: string;
  accidentDescription: string;
  reportNumber: string;
};

interface ReportContextProps {
  reportData: ReportData | null;
  setReportData: (data: ReportData | null) => void;
}

const ReportContext = createContext<ReportContextProps | undefined>(undefined);

export const ReportProvider = ({ children }: { children: ReactNode }) => {
  const [reportData, setReportData] = useState<ReportData | null>(null);

  return (
    <ReportContext.Provider value={{ reportData, setReportData }}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReport must be used within a ReportProvider');
  }
  return context;
};