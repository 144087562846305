import React from 'react';
import { useReport } from '../context/ReportContext';

const AccidentDescription = () => {
  const { reportData } = useReport();

  if (!reportData) return null;

  return (
    <div className="accident-description" style={{ height: "250px", overflowY: "scroll" }}>
      <h1 className="font-bold mt-4">Descripción del accidente</h1>
      <p className="text-sm">{reportData.accidentDescription}</p>
    </div>
  );
};

export default AccidentDescription;