import { useReactToPrint } from "react-to-print";
import { StatefulTargetBoard } from "./Board";
import { SidebarOptions } from "./SidebarOptions";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { LoadAccidentReportModal } from "./LoadAccidentReportModal";
import { Header } from './Header';
import { MAX_COLUMN, MAX_ROW, WIDTH_AND_HEIGHT } from "../constants";
import { Element as CustomElement } from "./Element/types";

export const MainLayout = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [boardItems, setBoardItems] = useState<CustomElement[]>([]);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const imageUploadRef = useRef<HTMLInputElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => {
      if (componentRef.current) {
        const container = document.createElement('div');
        const header = document.createElement('div');
        const board = document.createElement('div');
        const description = document.createElement('div');
        const legend = document.createElement('div');

        const headerElement = componentRef.current.querySelector('.header');
        const boardElement = componentRef.current.querySelector('.board');
        const descriptionElement = document.querySelector('.accident-description');

        if (headerElement && boardElement) {
          header.innerHTML = headerElement.outerHTML;
          board.innerHTML = boardElement.outerHTML;

          const svgElement = board.querySelector('svg');
          if (svgElement) {
            svgElement.removeAttribute('width');
            svgElement.removeAttribute('height');
            const viewBoxWidth = MAX_COLUMN * WIDTH_AND_HEIGHT;
            const viewBoxHeight = MAX_ROW * WIDTH_AND_HEIGHT;
            svgElement.setAttribute('viewBox', `0 0 ${viewBoxWidth} ${viewBoxHeight}`);
          }

          container.appendChild(header.cloneNode(true));
          container.appendChild(board);
          container.appendChild(document.createElement('div')).style.pageBreakAfter = 'always';
          container.appendChild(header.cloneNode(true));
          if (descriptionElement) {
            description.innerHTML = descriptionElement.outerHTML;
            description.style.pageBreakBefore = 'avoid';
            description.style.pageBreakInside = 'avoid';
            container.appendChild(description);
          }

          const legendContainer = document.createElement('div');
          legendContainer.style.pageBreakInside = 'avoid';
          legendContainer.style.pageBreakBefore = 'avoid';

          const legendTitle = document.createElement('h1');
          legendTitle.className = 'font-bold mt-4 mb-4';
          legendTitle.textContent = 'LEYENDA DEL CROQUIS';
          legendContainer.appendChild(legendTitle);
          
          legend.style.display = 'grid';
          legend.style.gridTemplateColumns = 'repeat(2, 1fr)';
          legend.style.gap = '20px';

          boardItems.forEach(item => {
            const legendItem = document.createElement('div');
            legendItem.style.display = 'grid';
            legendItem.style.gridTemplateColumns = 'repeat(2, 1fr)';
            legendItem.style.alignItems = 'center';
            legendItem.style.gap = '10px';

            const elementContainer1 = document.createElement('div');
            elementContainer1.className = 'Element-container';
            elementContainer1.innerHTML = `<img src="${item.source}" alt="${item.name}" style="display: block;"/>`;

            const name1 = document.createElement('p');
            name1.textContent = item.name;
            name1.style.margin = '0';

            legendItem.appendChild(elementContainer1);
            legendItem.appendChild(name1);

            legend.appendChild(legendItem);
          });
          legendContainer.appendChild(legend);
          container.appendChild(legendContainer);
        }

        const style = document.createElement('style');
        style.type = 'text/css';
        style.media = 'print';
        style.innerHTML = `
          @page:first {
            size: A4 landscape;
          }
          @page {
            size: A4 portrait;
          }
          div {
            box-sizing: border-box;
          }
          .print-container {
            width: 85%;
            margin: auto;
            padding: 20px;
          }
          .print-container .board {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
          }
          .header img {
            width: 130px !important;
          }
          .accident-description {
            margin: 20px 0;
            height: auto !important;
            overflow: visible !important;
          }
          .accident-description h1 {
            text-transform: uppercase !important;
            margin-bottom: 10px !important;
          }
          .accident-description p {
            text-align: justify !important;
          }
          .board {
            overflow: visible !important;
          }
          .Element-container {
            cursor: move;
            width: 150px;
            height: 150px;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .Element-container img {
            width: 100px !important;
          }
          .board-footer {
            display: none;
          }
          .moveable-control-box {
            display: none !important;
          }
          .moveable-control-box .moveable-line {
            display: none !important;
          }
          .moveable-control-box .moveable-control {
            display: none !important;
          }
        `;
        container.appendChild(style);

        container.classList.add('print-container');

        return container;
      }
      return null;
    },
  });

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      localStorage.removeItem('capturedMapImage');
    }
  };

  const navigate = useNavigate();

  const handleNavigateToMap = () => {
    navigate('/map');
  };

  useEffect(() => {
    const hasShownModal = localStorage.getItem('hasShownModal');
    if (!hasShownModal) {
      setShowModal(true);
      localStorage.setItem('hasShownModal', 'true');
    }
  }, []);

  return (
    <div ref={componentRef}>
      <Header />
      {showModal && <LoadAccidentReportModal showModal={showModal} setShowModal={setShowModal} />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "90vh",
        }}
      >
        <div
          style={{
            flex: 30,
            maxWidth: 300,
            height: "100%",
            padding: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SidebarOptions
            handlePrint={handlePrint}
            handleImageUpload={handleImageUpload}
            handleNavigateToMap={handleNavigateToMap}
            setShowModal={setShowModal}
            imageUploadRef={imageUploadRef}
          />
        </div>
        <div
          style={{
            flex: 70,
            padding: 10,
            height: "100%",
            overflow: "scroll",
          }}
        >
          <div className="board">
            <StatefulTargetBoard key={selectedImage} image={selectedImage} setBoardItems={setBoardItems} />
          </div>
        </div>
      </div>
    </div>
  );
};