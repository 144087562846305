export interface Sign {
    id: string;
    name: string;
    icon: string;
    source: string;
    height?: number;
    width?: number;
    top?: number;
    left?: number;
}

export const trafficSigns: Array<Sign> = [
    {
        id: "green-light",
        name: "Luz Verde",
        icon: "images/traffic-signs/green-light.png",
        source: "images/traffic-signs/green-light.png",
    },
    {
        id: "red-light",
        name: "Luz Roja",
        icon: "images/traffic-signs/red-light.png",
        source: "images/traffic-signs/red-light.png",
    },
    {
        id: "yellow-light",
        name: "Luz Amarilla",
        icon: "images/traffic-signs/yellow-light.png",
        source: "images/traffic-signs/yellow-light.png",
    },
    {
        id: "stop",
        name: "Señal de Alto",
        icon: "images/traffic-signs/stop.png",
        source: "images/traffic-signs/stop.png",
    },
    {
        id: "no-left",
        name: "No Girar a la Izquierda",
        icon: "images/traffic-signs/no-left.png",
        source: "images/traffic-signs/no-left.png",
    },
    {
        id: "no-right",
        name: "No Girar a la Derecha",
        icon: "images/traffic-signs/no-right.png",
        source: "images/traffic-signs/no-right.png",
    },
    {
        id: "no-u",
        name: "No Retorno",
        icon: "images/traffic-signs/no-u.png",
        source: "images/traffic-signs/no-u.png",
    },
    {
        id: "no-parking",
        name: "No Estacionar",
        icon: "images/traffic-signs/no-parking.png",
        source: "images/traffic-signs/no-parking.png",
    },
    {
        id: "people-crossing",
        name: "Cruce Peatonal",
        icon: "images/traffic-signs/people-crossing.png",
        source: "images/traffic-signs/people-crossing.png",
    },
    {
        id: "velocidad_30",
        name: "velocidad_30",
        icon: "images/traffic-signs/velocidad_30.png",
        source: "images/traffic-signs/velocidad_30.png",
    },
    {
        id: "velocidad_40",
        name: "velocidad_40",
        icon: "images/traffic-signs/velocidad_40.png",
        source: "images/traffic-signs/velocidad_40.png",
    },
    {
        id: "velocidad_70",
        name: "velocidad_70",
        icon: "images/traffic-signs/velocidad_70.png",
        source: "images/traffic-signs/velocidad_70.png",
    },
    {
        id: "velocidad_100",
        name: "velocidad_100",
        icon: "images/traffic-signs/velocidad_100.png",
        source: "images/traffic-signs/velocidad_100.png",
    },
    {
        id: "velocidad_obra_40",
        name: "velocidad_obra_40",
        icon: "images/traffic-signs/velocidad_obra_40.png",
        source: "images/traffic-signs/velocidad_obra_40.png",
    }
    ,
    {
        id: "velocidad_recomendada_50",
        name: "velocidad_recomendada_50",
        icon: "images/traffic-signs/velocidad_recomendada_50.png",
        source: "images/traffic-signs/velocidad_recomendada_50.png",
    },
    {
        id: "findeprohibicion_1",
        name: "findeprohibicion_1",
        icon: "images/traffic-signs/findeprohibicion_1.png",
        source: "images/traffic-signs/findeprohibicion_1.png",
    },
    {
        id: "autovia_1",
        name: "autovia_1",
        icon: "images/traffic-signs/autovia_1.png",
        source: "images/traffic-signs/autovia_1.png",
    },
    {
        id: "aviso_obras_1",
        name: "aviso_obras_1",
        icon: "images/traffic-signs/aviso_obras_1.png",
        source: "images/traffic-signs/aviso_obras_1.png",
    },
    {
        id: "avisodirecciones_1",
        name: "avisodirecciones_1",
        icon: "images/traffic-signs/avisodirecciones_1.png",
        source: "images/traffic-signs/avisodirecciones_1.png",
    },
    {
        id: "avisodirecciones_2",
        name: "avisodirecciones_2",
        icon: "images/traffic-signs/avisodirecciones_2.png",
        source: "images/traffic-signs/avisodirecciones_2.png",
    },
    {
        id: "baliza_1",
        name: "baliza_1",
        icon: "images/traffic-signs/baliza_1.png",
        source: "images/traffic-signs/baliza_1.png",
    },
    {
        id: "baliza_2",
        name: "baliza_2",
        icon: "images/traffic-signs/baliza_2.png",
        source: "images/traffic-signs/baliza_2.png",
    },
    {
        id: "baliza_3",
        name: "baliza_3",
        icon: "images/traffic-signs/baliza_3.png",
        source: "images/traffic-signs/baliza_3.png",
    }
    ,
    {
        id: "baliza_4",
        name: "baliza_4",
        icon: "images/traffic-signs/baliza_4.png",
        source: "images/traffic-signs/baliza_4.png",
    },
    {
        id: "baliza_5",
        name: "baliza_5",
        icon: "images/traffic-signs/baliza_5.png",
        source: "images/traffic-signs/baliza_5.png",
    },
    {
        id: "baliza_6",
        name: "baliza_6",
        icon: "images/traffic-signs/baliza_6.png",
        source: "images/traffic-signs/baliza_6.png",
    },
    {
        id: "cedaelpaso_1",
        name: "cedaelpaso_1",
        icon: "images/traffic-signs/cedaelpaso_1.png",
        source: "images/traffic-signs/cedaelpaso_1.png",
    },
    {
        id: "informacion_bicicletas",
        name: "informacion_bicicletas",
        icon: "images/traffic-signs/informacion_bicicletas.png",
        source: "images/traffic-signs/informacion_bicicletas.png",
    },
    {
        id: "obligacion_2",
        name: "obligacion_2",
        icon: "images/traffic-signs/obligacion_2.png",
        source: "images/traffic-signs/obligacion_2.png",
    },
    {
        id: "obligacion_1",
        name: "obligacion_1",
        icon: "images/traffic-signs/obligacion_1.png",
        source: "images/traffic-signs/obligacion_1.png",
    },
    {
        id: "obligacion_3",
        name: "obligacion_3",
        icon: "images/traffic-signs/obligacion_3.png",
        source: "images/traffic-signs/obligacion_3.png",
    },
    {
        id: "obligacion_4",
        name: "obligacion_4",
        icon: "images/traffic-signs/obligacion_4.png",
        source: "images/traffic-signs/obligacion_4.png",
    },
    {
        id: "obligacion_6",
        name: "obligacion_6",
        icon: "images/traffic-signs/obligacion_6.png",
        source: "images/traffic-signs/obligacion_6.png",
    },
    {
        id: "obligacion_7",
        name: "obligacion_7",
        icon: "images/traffic-signs/obligacion_7.png",
        source: "images/traffic-signs/obligacion_7.png",
    },
    {
        id: "parking",
        name: "parking",
        icon: "images/traffic-signs/parking.png",
        source: "images/traffic-signs/parking.png",
    },
    {
        id: "paso_peatones_1",
        name: "paso_peatones_1",
        icon: "images/traffic-signs/paso_peatones_1.png",
        source: "images/traffic-signs/paso_peatones_1.png",
    },
    {
        id: "peligro_1",
        name: "peligro_1",
        icon: "images/traffic-signs/peligro_1.png",
        source: "images/traffic-signs/peligro_1.png",
    },
    {
        id: "peligro_2",
        name: "peligro_2",
        icon: "images/traffic-signs/peligro_2.png",
        source: "images/traffic-signs/peligro_2.png",
    },
    {
        id: "peligro_3",
        name: "peligro_3",
        icon: "images/traffic-signs/peligro_3.png",
        source: "images/traffic-signs/peligro_3.png",
    },
    {
        id: "peligro_4",
        name: "peligro_4",
        icon: "images/traffic-signs/peligro_4.png",
        source: "images/traffic-signs/peligro_4.png",
    },
    {
        id: "peligro_5",
        name: "peligro_5",
        icon: "images/traffic-signs/peligro_5.png",
        source: "images/traffic-signs/peligro_5.png",
    },
    {
        id: "peligro_6",
        name: "peligro_6",
        icon: "images/traffic-signs/peligro_6.png",
        source: "images/traffic-signs/peligro_6.png",
    },
    {
        id: "peligro_7",
        name: "peligro_7",
        icon: "images/traffic-signs/peligro_7.png",
        source: "images/traffic-signs/peligro_7.png",
    },
    {
        id: "peligro_8",
        name: "peligro_8",
        icon: "images/traffic-signs/peligro_8.png",
        source: "images/traffic-signs/peligro_8.png",
    },
    {
        id: "peligro_9",
        name: "peligro_9",
        icon: "images/traffic-signs/peligro_9.png",
        source: "images/traffic-signs/peligro_9.png",
    },
    {
        id: "peligro_10",
        name: "peligro_10",
        icon: "images/traffic-signs/peligro_10.png",
        source: "images/traffic-signs/peligro_10.png",
    },
    {
        id: "peligro_11",
        name: "peligro_11",
        icon: "images/traffic-signs/peligro_11.png",
        source: "images/traffic-signs/peligro_11.png",
    },
    {
        id: "peligro_12",
        name: "peligro_12",
        icon: "images/traffic-signs/peligro_12.png",
        source: "images/traffic-signs/peligro_12.png",
    },
    {
        id: "peligro_13",
        name: "peligro_13",
        icon: "images/traffic-signs/peligro_13.png",
        source: "images/traffic-signs/peligro_13.png",
    },
    {
        id: "peligro_14",
        name: "peligro_14",
        icon: "images/traffic-signs/peligro_14.png",
        source: "images/traffic-signs/peligro_14.png",
    },
    {
        id: "peligro_15",
        name: "peligro_15",
        icon: "images/traffic-signs/peligro_15.png",
        source: "images/traffic-signs/peligro_15.png",
    },
    {
        id: "peligro_16",
        name: "peligro_16",
        icon: "images/traffic-signs/peligro_16.png",
        source: "images/traffic-signs/peligro_16.png",
    },
    {
        id: "prohibido_2",
        name: "prohibido_2",
        icon: "images/traffic-signs/prohibido_2.png",
        source: "images/traffic-signs/prohibido_2.png",
    },
    {
        id: "prohibido_3",
        name: "prohibido_3",
        icon: "images/traffic-signs/prohibido_3.png",
        source: "images/traffic-signs/prohibido_3.png",
    },
    {
        id: "prohibido_4",
        name: "prohibido_4",
        icon: "images/traffic-signs/prohibido_4.png",
        source: "images/traffic-signs/prohibido_4.png",
    },
    {
        id: "prohibido_5",
        name: "prohibido_5",
        icon: "images/traffic-signs/prohibido_5.png",
        source: "images/traffic-signs/prohibido_5.png",
    },
    {
        id: "prohibido_6",
        name: "prohibido_6",
        icon: "images/traffic-signs/prohibido_6.png",
        source: "images/traffic-signs/prohibido_6.png",
    },
    {
        id: "prohibido_7",
        name: "prohibido_7",
        icon: "images/traffic-signs/prohibido_7.png",
        source: "images/traffic-signs/prohibido_7.png",
    },
    {
        id: "prohibido_8",
        name: "prohibido_8",
        icon: "images/traffic-signs/prohibido_8.png",
        source: "images/traffic-signs/prohibido_8.png",
    },
    {
        id: "prohibido_9",
        name: "prohibido_9",
        icon: "images/traffic-signs/prohibido_9.png",
        source: "images/traffic-signs/prohibido_9.png",
    },
    {
        id: "prohibido_10",
        name: "prohibido_10",
        icon: "images/traffic-signs/prohibido_10.png",
        source: "images/traffic-signs/prohibido_10.png",
    },
    {
        id: "prohibido_11",
        name: "prohibido_11",
        icon: "images/traffic-signs/prohibido_11.png",
        source: "images/traffic-signs/prohibido_11.png",
    },
    {
        id: "prohibido_12",
        name: "prohibido_12",
        icon: "images/traffic-signs/prohibido_12.png",
        source: "images/traffic-signs/prohibido_12.png",
    },
    {
        id: "prohibido_13",
        name: "prohibido_13",
        icon: "images/traffic-signs/prohibido_13.png",
        source: "images/traffic-signs/prohibido_13.png",
    },
    {
        id: "prohibido_14",
        name: "prohibido_14",
        icon: "images/traffic-signs/prohibido_14.png",
        source: "images/traffic-signs/prohibido_14.png",
    },
    {
        id: "prohibido_15",
        name: "prohibido_15",
        icon: "images/traffic-signs/prohibido_15.png",
        source: "images/traffic-signs/prohibido_15.png",
    },
    {
        id: "prohibido_16",
        name: "prohibido_16",
        icon: "images/traffic-signs/prohibido_16.png",
        source: "images/traffic-signs/prohibido_16.png",
    },
    {
        id: "prohibido_17",
        name: "prohibido_17",
        icon: "images/traffic-signs/prohibido_17.png",
        source: "images/traffic-signs/prohibido_17.png",
    },
    {
        id: "semaforo_1",
        name: "semaforo_1",
        icon: "images/traffic-signs/semaforo_1.png",
        source: "images/traffic-signs/semaforo_1.png",
    },
    {
        id: "semaforo_2",
        name: "semaforo_2",
        icon: "images/traffic-signs/semaforo_2.png",
        source: "images/traffic-signs/semaforo_2.png",
    },
    {
        id: "suelo_8",
        name: "suelo_8",
        icon: "images/traffic-signs/suelo_8.png",
        source: "images/traffic-signs/suelo_8.png",
    },
    {
        id: "suelo_9",
        name: "suelo_9",
        icon: "images/traffic-signs/suelo_9.png",
        source: "images/traffic-signs/suelo_9.png",
    },
    {
        id: "suelo_10",
        name: "suelo_10",
        icon: "images/traffic-signs/suelo_10.png",
        source: "images/traffic-signs/suelo_10.png",
    },
    {
        id: "suelo_11",
        name: "suelo_11",
        icon: "images/traffic-signs/suelo_11.png",
        source: "images/traffic-signs/suelo_11.png",
    },
    {
        id: "suelo_12",
        name: "suelo_12",
        icon: "images/traffic-signs/suelo_12.png",
        source: "images/traffic-signs/suelo_12.png",
    },
    {
        id: "suelo_13",
        name: "suelo_13",
        icon: "images/traffic-signs/suelo_13.png",
        source: "images/traffic-signs/suelo_13.png",
    },
    {
        id: "suelo_14",
        name: "suelo_14",
        icon: "images/traffic-signs/suelo_14.png",
        source: "images/traffic-signs/suelo_14.png",
    },
    {
        id: "pasopeaton_1",
        name: "pasopeaton_1",
        icon: "images/traffic-signs/paso_peatones_1.png",
        source: "images/traffic-signs/paso_peatones_1.png",
    }
    




];

export const streetSigns: Array<Sign> = [
    {
        id: "horizontal",
        name: "Calle Horizontal",
        icon: "images/street/horizontals.png",
        source: "images/street/horizontals.png",
        height: 100,
        width: 100,
        top: 0,
        left: 0,
    },
    {
        id: "vertical",
        name: "Calle Vertical",
        icon: "images/street/verticals.png",
        source: "images/street/verticals.png",
        height: 100,
        width: 100,
        top: 0,
        left: 0,
    },
    {
        id: "T-down",
        name: "Intersección en T Abajo",
        icon: "images/street/T-downs.png",
        source: "images/street/T-downs.png",
        height: 200,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "T-up",
        name: "Intersección en T Arriba",
        icon: "images/street/T-ups.png",
        source: "images/street/T-ups.png",
        height: 200,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "T-left",
        name: "Intersección en T Izquierda",
        icon: "images/street/T-lefts.png",
        source: "images/street/T-lefts.png",
        height: 300,
        width: 200,
        top: 0,
        left: 0,
    },
    {
        id: "T-right",
        name: "Intersección en T Derecha",
        icon: "images/street/T-rights.png",
        source: "images/street/T-rights.png",
        height: 300,
        width: 200,
        top: 0,
        left: 0,
    },
    {
        id: "intersection",
        name: "Intersección",
        icon: "images/street/intersections.png",
        source: "images/street/intersections.png",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "round-intersection",
        name: "Glorieta",
        icon: "images/street/round-intersections.png",
        source: "images/street/round-intersections.png",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    }
];

export const carsSings: Array<Sign> = [
    {
        id: "coche",
        name: "Coche",
        icon: "images/cars/coche.svg",
        source: "images/cars/coche.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "coche2",
        name: "coche2",
        icon: "images/cars/coche2.svg",
        source: "images/cars/coche2.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "coche3",
        name: "coche3",
        icon: "images/cars/coche3.svg",
        source: "images/cars/coche3.svg",
        height: 300,
        width: 300,
        top: 0,
        left: 0,
    },
    {
        id: "bus",
        name: "Autobús",
        icon: "images/cars/bus.svg",
        source: "images/cars/bus.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "sidecar",
        name: "sidecar",
        icon: "images/cars/sidecar.svg",
        source: "images/cars/sidecar.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "moto",
        name: "moto",
        icon: "images/cars/moto.svg",
        source: "images/cars/moto.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "moto2",
        name: "moto2",
        icon: "images/cars/moto2.svg",
        source: "images/cars/moto2.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
];

export const especialCarsSings: Array<Sign> = [
    {
        id: "camion",
        name: "camion",
        icon: "images/cars/camion.svg",
        source: "images/cars/camion.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial",
        name: "especial",
        icon: "images/cars/especial.svg",
        source: "images/cars/especial.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_2",
        name: "carruaje",
        icon: "images/cars/especial_2.svg",
        source: "images/cars/especial_2.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_3",
        name: "especial_3",
        icon: "images/cars/especial_3.svg",
        source: "images/cars/especial_3.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_4",
        name: "especial_4",
        icon: "images/cars/especial_4.svg",
        source: "images/cars/especial_4.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_5",
        name: "especial_5",
        icon: "images/cars/especial_5.svg",
        source: "images/cars/especial_5.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_6",
        name: "especial_6",
        icon: "images/cars/especial_6.svg",
        source: "images/cars/especial_6.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_7",
        name: "especial_7",
        icon: "images/cars/especial_7.svg",
        source: "images/cars/especial_7.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_8",
        name: "especial_8",
        icon: "images/cars/especial_8.svg",
        source: "images/cars/especial_8.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_9",
        name: "especial_9",
        icon: "images/cars/especial_9.svg",
        source: "images/cars/especial_9.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
    {
        id: "especial_10",
        name: "especial_10",
        icon: "images/cars/especial_10.svg",
        source: "images/cars/especial_10.svg",
        height: 150,
        width: 150,
        top: 0,
        left: 0,
    },
];

export const peopleSings: Array<Sign> = [
    {
        id: "persona1",
        name: "persona1",
        icon: "images/people/persona1.svg",
        source: "images/people/persona1.svg",
    },
    {
        id: "persona2",
        name: "persona2",
        icon: "images/people/persona2.svg",
        source: "images/people/persona2.svg",
    },
    {
        id: "persona3",
        name: "persona3",
        icon: "images/people/persona3.svg",
        source: "images/people/persona3.svg",
    },
];

export const objectSings: Array<Sign> = [
    {
        id: "mobiliario_7",
        name: "mobiliario_7",
        icon: "images/objects/mobiliario_7.png",
        source: "images/objects/mobiliario_7.png",
    },
    {
        id: "mobiliario_8",
        name: "mobiliario_8",
        icon: "images/objects/mobiliario_8.png",
        source: "images/objects/mobiliario_8.png",
    },
    {
        id: "mobiliario_9",
        name: "mobiliario_9",
        icon: "images/objects/mobiliario_9.png",
        source: "images/objects/mobiliario_9.png",
    },
    {
        id: "mobiliario_10",
        name: "mobiliario_10",
        icon: "images/objects/mobiliario_10.png",
        source: "images/objects/mobiliario_10.png",
    },
    {
        id: "mobiliario_11",
        name: "mobiliario_11",
        icon: "images/objects/mobiliario_11.png",
        source: "images/objects/mobiliario_11.png",
    },
];
